<template>
    <div style="width: 100%;height: 100%; border-radius: 10px">
        <video ref="videoPlayer" data-setup='{ "playbackRates": [0.5, 1, 1.5, 2] }' controlsList="nodownload" class="video-js vjs-theme-forest h-100 w-100"></video>
    </div>
</template>

<script>
import videojs from 'video.js';
import videojsoverlay from 'videojs-overlay';
import watermark from 'videojs-awesome-watermark';

export default {
    name: "VideoPlayer",
    props: {
        options: {
            type: Object,
            default() {
                return {};
            }
        },
        user: {
            type: Object,
            default() {
                return {};
            }
        }
    },
    data() {
        return {
            player: null
        }
    },
    
    mounted() {
        this.player = videojs(this.$refs.videoPlayer, this.options, function onPlayerReady() {
            
        });

        this.player.awesomeWatermark( {
            // Watermark types are 'text' and 'img'
            type: 'text',

            // General options
            intermittent: false,
            hiddenTime: 0,
            visibleTime: 5,
            opacity: 0.4,

            // Positioning
            // when 'position' is set, the other positioning options (left, right, top and bottom) will be ignored
            // 'random', 'top-left', 'top-right', 'bottom-left', 'bottom-right', 'center', 'top-center'
            // 'bottom-center', 'offcenter-center', 'offcenter-left', 'offcenter-right', 
            position: ['bottom','right'],
            positionUnit: 'px',  // the CSS unit for positioning
            randomPositionSwitchingTime: 30, // random position switching time
            // make sure you set a pair [left,right] / [top,bottom], (like the 'position' option), 
            // otherwise you may get undesired results
            top: '20',
            bottom: null,
            left: null,
            right: '20',

            // Text watermark properties
            fontColor: 'white',
            fontFamily: 'Arial',
            fontSize: '30',
            fontSizeUnit: 'pixels',
            text: "licenciado para: " + this.user.name + " - " + this.user.cpf,
            });
     
    },
    beforeUnmount() {
        if (this.player) {
            this.player.dispose()
        }
    }
}
</script>