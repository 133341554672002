<script setup lang="ts">

</script>

<template>
  <div class="card card-flush ">
    <div class="card-header justify-content-start align-items-center pt-4">
      <div class="w-50px flex-shrink-0 me-2">
        <div class="symbol symbol-50px symbol-circle me-5">
          <span class="symbol-label ">
            <img src="" class="h-75 align-self-center" alt="" />
          </span>
        </div>
      </div>
      <span class=" mt-4 w-25">
        <div  class="form-control form-control-solid py-2"></div>
      </span>
    </div>
    <div class="card-body pt-2 pb-5">
      <div class="position-relative w-100">
        <div  class="form-control form-control-solid py-10">
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">

</style>